.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #212b59;
  color: #ffffff;
  padding: 10px;
}

.burger-menu {
  cursor: pointer;
  font-size: 1.5rem;
  order: 1;
  margin: 0 0 0 5%;
  color: #faba20;
}

.navbar-brand {
  order: 3;
  margin-left: auto;
}

.nav-items {
  display: none;
  order: 2;
  list-style: none;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: auto;
}

.nav-items li {
  margin: 0;
}

@media (max-width: 768px) {
  .nav-items {
    display: none;
  }
}

@media (min-width: 769px) {
  .nav-items {
    display: flex;
  }

  .burger-menu {
    display: none;
  }
}

.navbar img {
  width: 100px;
  height: auto;
  max-width: 100%;
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .navbar img {
    width: 80px;
  }
}

@media screen and (max-device-width: 736px) and (orientation: landscape) {
  .navbar img {
    width: 80px;
  }
}

.navbar li {
  display: flex;
  align-items: center;
  position: relative;
}

.navbar li:not(:last-child)::after {
  content: '|';
  margin-left: 10px;
  color: #faba20;
  font-size: 16px;
}

.navbar li:not(:first-child)::before {
  margin-right: 10px;
  color: #faba20;
  font-size: 16px;
}

.navbar a {
  text-decoration: none;
  color: #faba20;
  font-weight: bold;
  font-size: 16px;
}

.contact-section {
  display: flex;
  justify-content: center;  /* Center the content horizontally */
  align-items: center;  /* Center the content vertically */
  margin-bottom: 20px;  /* Optional: Add space between the sections */
}

.phone-container, .email-container {
  display: flex;
  align-items: center;  /* Align icon and text vertically */
  margin-right: 20px;  /* Space between icon and text */
}

.phone-icon-container, .email-icon-container {
  background-color: #faba20;  /* Yellow circle */
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;  /* Add space between the icon and text */
}

.phone-icon, .email-icon {
  color: white;  /* Icon color */
  font-size: 20px;
}

.contact-text {
  font-size: 16px;
  text-align: center;
}

@media (max-width: 768px) {
  .contact-section {
      display: none; /* Hide the entire contact section on mobile */
  }
}