/* Home Page Styling */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}

/* Image Container */
.image-container {
  position: relative;
  width: 100%;
  height: 50vh; /* Adjusts height dynamically */
  z-index: 1;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(30%);
}

/* Content Section */
.content-container {
  text-align: center;
  margin: 20px;
  padding: 0 10%;
}

.content-container h1 {
  color: #2f1980;
  font-size: 2.5rem; /* Default desktop size */
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .content-container h1 {
    font-size: 2rem; /* Smaller for tablets */
  }
}

@media (max-width: 480px) {
  .content-container h1 {
    font-size: 1.5rem; /* Smaller for mobile */
  }
}

.content-container hr {
  border: 0;
  height: 2px;
  background-color: #3c6d71;
  width: 50%;
  margin: 0 auto 20px auto;
}

.content-container p {
  color: #2f1980;
  font-size: 1.2em;
  line-height: 1.5;
}

/* Widgets Section */
.widgets-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px; /* Adds spacing */
  margin: 20px auto;
  padding: 0 10%;
}

.widgets-container > .widget {
  flex: 1 1 calc(20% - 20px); /* Default: 5 items per row */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
}

@media (max-width: 1024px) {
  .widgets-container > .widget {
    flex: 1 1 calc(33.33% - 20px); /* 3 items per row */
  }
}

@media (max-width: 768px) {
  .widgets-container > .widget {
    flex: 1 1 calc(50% - 20px); /* 2 items per row */
  }
}

@media (max-width: 480px) {
  .widgets-container {
    padding: 0;
  }
  .widgets-container > .widget {
    flex: 1 1 100%; /* Stacks widgets */
  }
}

@media (max-width: 768px) {
  div[style*="display: flex; justify-content: center; align-items: center;"] > div {
    max-width: 100%; /* Use the full width */
  }

  div[style*="display: flex; justify-content: center; align-items: center; gap:"] > div {
    flex: 1 1 100%; /* Display one image at a time */
  }
}

.text-section {
  max-width: 40%;
  width: 50%;
  text-align: justify;
  text-rendering: optimizeLegibility;
  margin-bottom: 20px;
}

.text-section h2 {
  border-bottom: 4px solid #faba20;
  padding-bottom: 10px;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
  .text-section {
    max-width: 90%;
    width: 90%;
  }
}

/* Default styles for the image */
.about-us-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  /* Ensure the image takes up full width of the container on desktop */
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
  /* Hide the image on mobile */
  .about-us-image {
    display: none;
  }

  /* Adjust text section width on mobile */
  .text-section {
    max-width: 90%;
    width: 90%;
  }
}

@media (max-width: 768px) {
  .mobile-text-style {
    width: 180%;
    margin-left: -10%;
  }
}

/* Default styles */
.image-container {
  position: relative;
  width: 100%;
  height: 700px; /* Default height */
  z-index: 1;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.text-overlay {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 6%; /* Align to the left side */
  transform: translateY(-50%); /* Adjust vertical alignment */
  color: #fff; /* White text for contrast */
  text-align: left; /* Align text to the left */
}

.text-overlay h5 {
  background: #faba20; /* Button-like background */
  padding: 5px 10px; /* Padding for button effect */
  border-radius: 5px; /* Rounded corners */
  display: block; /* Ensure each h5 is on a new line */
  font-size: calc(1.5vw + 1vh); /* Responsive font size */
  margin: 5px 0; /* Space between each line */
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

@media (max-width: 768px) {
  .text-overlay {
    top: 20%; /* Adjust position for smaller screens */
    left: 5%; /* Align closer to the left on mobile */
  }

  .text-overlay h5 {
    font-size: calc(0.1vw + 1vh); /* Smaller font size for mobile */
    padding: 3px 8px; /* Compact padding for mobile */
    margin: 3px 0; /* Reduce spacing between lines */
  }

  /* Reduce the space between the image and the under widget */
  .image-container {
    height: 30vh; /* Reduce height of image container */
    margin-bottom: 10px; /* Reduce space between image and widget */
  }
}

@media (max-width: 768px) {
  .mobile-only {
    display: block; /* Show the fifth item on mobile view */
  }
}