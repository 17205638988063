/* Sidebar.css */

.sidebar {
    position: fixed;
    top: 0;
    z-index: 2;
    right: 0;
    width: 250px;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #faba20;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .sidebar-open {
    transform: translateX(0);
  }
  
  .sidebar-content {
    padding: 2rem;
  }
  
  .sidebar-content ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar-content ul li {
    margin: 1rem 0;
  }
  
  .sidebar-content ul li a {
    color: #faba20;
    text-decoration: none;
  }  